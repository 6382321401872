<template>
  <div class="statistic_box">
    <el-scrollbar class="quest-scroll">
      <div class="analysis">
        <ul class="list clearfix">
          <li v-for="(item,index) in statisticalList" :key="index">
            <div class="in">
              <div class="tt">
                <h3 class="oe1">{{ item.title }}</h3>
              </div>
              <div class="info clearfix">
                <p>问卷编号：{{ item.number }}</p>
                <p>收到数据：<span class="blue">{{ item.receivedData }}</span> 份</p>
                <p>创建报表：{{ item.time }}</p>
                <p>报表更新：{{ item.updateTime }}</p>
              </div>
              <div class="handle clearfix">
                <p>
                  <a href="JavaScript:;" class="tj" @click="skip1"><i></i>统计报告</a>
                </p>
                <p>
                  <a href="JavaScript:;" class="source" @click="skip2"><i></i>来源分析</a>
                </p>
                <p>
                  <a href="JavaScript:;" class="down" @click="skip3"><i></i>完成率分析</a>
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="in">
              <router-link class="add_btn" to="#">
                <i></i>
                <p class="new_btn" @click="centerDialogVisible = true">+ 新建报表</p>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </el-scrollbar>
    <!-- 弹窗 -->
    <el-dialog
        title="创建统计报表"
        :visible.sync="centerDialogVisible"
        width="27%"
    >
      <div class="new_content">
        <div class="survey_box">
          <div class="survey_name">问卷名称:</div>
          <div class="survey_down">
            <el-select style="width: 350px;font-size: 12px" v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <p class="sur_tips" v-show="statement">
          该问卷已创建过报表，是否立即更新？
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data (){
    return {
      centerDialogVisible: false, //弹窗开关
      options: [{ //弹窗数据
        value: '2020年季度园区发展调研',
        label: '2020年季度园区发展调研'
      }, {
        value: '2021年季度园区发展调研',
        label: '2021年季度园区发展调研'
      }, {
        value: '2022年季度园区发展调研',
        label: '2022年季度园区发展调研'
      }, {
        value: '2023年季度园区发展调研',
        label: '2023年季度园区发展调研'
      }, {
        value: '2024年季度园区发展调研',
        label: '2024年季度园区发展调研'
      }],
      value:'', //弹窗的值
      statement:false, //已创建表单提示开关
      statisticalList:[{ //报表数据
        title:'2020年季度园区发展调研',
        number:82289010,
        receivedData:1036,
        time:'2020年6月23日',
        updateTime:'2020年6月23日'
      },
        {
          title:'2021年季度园区发展调研',
          number:82289011,
          receivedData:1037,
          time:'2020年6月24日',
          updateTime:'2020年6月24日'
        },
        {
          title:'2022年季度园区发展调研',
          number:82289012,
          receivedData:1038,
          time:'2020年6月25日',
          updateTime:'2020年6月25日'
        },
        {
          title:'2022年季度园区发展调研',
          number:82289012,
          receivedData:1038,
          time:'2020年6月25日',
          updateTime:'2020年6月25日'
        },
        {
          title:'2029年季度园区发展调研',
          number:82289012,
          receivedData:1038,
          time:'2020年6月25日',
          updateTime:'2020年6月25日'
        }
      ],
      titles: [] //获取已添加问卷的titles
    }
  },
  created() {
    this.statisticalList.forEach(item => {
      this.titles.push(item.title); // 将title值存入titles数组
    });
  },
  watch: { //监听value的变化，注意selected选中的值为value，不是label，变化之后如果相同就讲statement的值变化
    value(newVal) {
      let found = false;
      this.titles.forEach((title) => {
        if (title === newVal) {
          this.statement = true; // 如果title和newVal相同，将statement设为true
          found = true;
        }
      });

      if (!found) {
        this.statement = false; // 如果没有找到匹配的title，将statement设为false
      }
    }
  },
  methods: {
    skip1(){
      this.$router.push({ path: '/report', query: { activeName: 'first' } });
    },
    skip2(){
      this.$router.push({ path: '/report', query: { activeName: 'second' } });
    },
    skip3(){
      this.$router.push({ path: '/report', query: { activeName: 'third' } });
    }
  }
}
</script>

<style scoped lang="scss">
.statistic_box{
  width: 100%;
  ::v-deep .el-dialog__footer{
    text-align: center;
  }
  .analysis {
    padding-top: 18px;
    .list {
      padding: 0 9px;
      li {
        float: left;
        width: 33.33%;
        margin-bottom: 18px;
      }
      .in {
        margin: 0 9px;
        background-color: #fff;
        border: 1px solid #eeeff2;
        .new_btn{
          cursor: pointer;
        }
      }
      .tt {
        height: 63px;
        line-height: 63px;
        padding: 0 22px;
        border-bottom: 1px solid #eeeff2;
      }
      h3 {
        font-size: 18px;
      }
      .info {
        padding: 20px 14% 20px 22px;
        border-bottom: 1px solid #eeeff2;
        p {
          float: left;
          width: 50%;
          color: #60696f;
          line-height: 32px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .blue {
          color: #1190f3;
        }
      }
      .handle {
        background: #F9FAFD;
        p {
          position: relative;
          float: left;
          width: 33.333%; //四个的时候为25
          text-align: center;
          line-height: 49px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 13px;
            width: 1px;
            height: 24px;
            background-color: #E1E8EC;
          }
          &:last-child {
            &:after {
              width: 0;
            }
          }
        }
        a {
          color: #60696f;
          &:hover {
            color: #1190f3;
            i {
              background-position-x: -20px;
            }
          }
        }
        i {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 2px;
          width: 20px;
          height: 20px;
          background: url(~@/assets/statistics/analysis_icon.png) no-repeat 0 3px;
        }
        .source {
          i {
            background-position-y: -17px;
          }
        }
        .down {
          i {
            background-position-y: -37px;
          }
        }
        .delete {
          i {
            background: url(~@/assets/statistics/survey_icon.png) no-repeat 0 -50px;
          }
        }
      }
      .add_btn {
        display: block;
        text-align: center;
        color: #1190f3;
        font-size: 14px;
        height: 164px;
        padding-top: 54px;
        i {
          display: block;
          width: 68px;
          height: 68px;
          margin: 0 auto 15px;
          background: url(~@/assets/statistics/analysis_add.png) center no-repeat;
        }
      }
    }
  }
  //弹窗
  .new_content{
    .survey_box{
      display: flex;
      align-items: center;
      margin-left: 20px;
      .survey_name{
        font-family: 'MicrosoftYaHei-Bold';
        font-weight: bold;
        font-size: 12px;
        margin-right: 16px;
      }
      ::v-deep .el-select .el-input {
        height: 34px;
        line-height: 34px;
        font-size: 12px;
      }
      ::v-deep .el-select .el-input__icon {
        line-height: 34px;
      }
      ::v-deep .el-select .el-input__inner {
        height: 34px;
        line-height: 34px;
        color: #242D33;
      }
    }
    .sur_tips{
      color: #FD5451;
      font-size: 12px;
      margin-left: 88px;
      margin-top: 12px;
    }
  }
}
::v-deep .quest-scroll {
  height: calc(100vh - 70px);
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
</style>